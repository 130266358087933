
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F48d55fd8-4310-45ce-9379-978b802406c4?alt=media&token=74a9cd74-50ad-4e92-b307-2dee68b1c742"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F3c6afc03-a003-4e7a-93fc-a8d24f885812?alt=media&token=5a39839b-b1fd-4a2b-8b4a-43465717a5a6"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F654e6172-1f69-4e3b-87e6-79f163cbe8de?alt=media&token=4aa2a91f-8fc9-4ae1-ac68-0ee68691717f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Fdba4c1cb-85e3-40ac-9b0d-7262e6121a19?alt=media&token=231f96b9-6fc1-4188-96e8-ece35dc7336e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Ffaa546e1-0c9f-436a-88af-4d6e31dd77f5?alt=media&token=792923fc-2a2e-444c-9d9b-26a8dc119a0c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F73319fc1-f9f2-4c72-b9b8-f5b42573a1aa?alt=media&token=4f41d197-3bf0-4eb6-a123-29c229df724e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Fb1c9b00d-5cd6-4c91-840b-0b2646d14648?alt=media&token=0e767313-d9ba-4713-bcd4-54b4286fb223"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F939d9bcd-ce04-4595-b7d3-55a08fbd87e6?alt=media&token=8dd34189-672c-46af-8b23-1d7705747a51"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F2d0d7f5d-5725-474d-8d54-d8173e50fd77?alt=media&token=f5911bd8-afd9-44a3-8291-d5e859773862"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F1cab1d25-9948-40d0-9c93-7baca054e8b2?alt=media&token=6e1b373e-9737-40fe-8e93-c9a1e5370666"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F19d4d88e-7927-4645-a10a-7412cf1cee11?alt=media&token=d400f69f-be6b-4c54-ac9a-400c704d1a2d"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F6d17494d-42cc-419c-84e4-0158e7138954?alt=media&token=2af8c67d-6993-45ae-a68c-4a0b32f2c68e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F6c7abffc-d3ee-4c7d-8ef7-b5ad60eba901?alt=media&token=1c1ace19-924e-4892-a058-547e788684d2"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F3ddd37d9-84ff-45b3-accb-1401bc574438?alt=media&token=421471de-410f-4af5-a77f-e9a12668b0ed"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F14defd98-a446-424a-9217-8b5b2ff9d235?alt=media&token=f949383d-941c-46b8-9310-9fb4d28158e3"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Fd8c03dcb-dc3e-4549-812f-b181e2860e61?alt=media&token=bec03547-80fb-4ea3-81f9-730cdfcd825e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F00ab3c71-52ea-4b88-a4b6-87f6fa7d16f4?alt=media&token=b760ea36-bf51-4645-a6bc-d43fe193a43b"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Fe1b8fe78-f77b-4fb3-b7a1-20cd0b525a4f?alt=media&token=3879637d-1e3b-468c-ba31-1df46c391819"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F57c80373-96d4-4970-8219-716a04716a44?alt=media&token=f3bad27f-bb01-4263-bceb-edf700cf37a4"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F22ddd032-b9b0-406d-8999-36f5aae720b2?alt=media&token=acaed76b-8000-400c-9bb6-39bb90f1b909"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F0a24c721-dc3b-489b-b4e6-0bddb743aacf?alt=media&token=79632c4a-ec29-4c58-8859-215c83c32d4e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2F80c77259-b520-4c09-9b2e-74338a88c015?alt=media&token=3d4cd6b2-1613-4f46-b2d5-d16331d771f3"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2Fd4bc618c-2b4d-4c22-8fa0-26ea0156b380?alt=media&token=747430dc-d598-4f08-b6ee-23d670c7530f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU5ES%2FDIVU5ESMAT.pdf?alt=media&token=903d2e5f-a923-4396-8669-aaf8b98435ca' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
